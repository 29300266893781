<template>
    <div>
        <Topbar></Topbar>
        <div class="pay-top">
            <div class="pay-top-font">
                <div class="pay-top-1">订单提交成功，现在只差最后一步啦！</div>
                <div class="pay-top-2">请您在提交订单后 <div class="pay-time">2小时00分</div> 内完成支付，否则订单会自动取消！</div>
            </div>
            <div class="pay-bottom-font">
                <div>halsanr画尚轻奢玄关背景墙抽象壁画现代简约空间延伸装饰画大气罗马建筑挂画</div>
                <div style="margin-top:10px">收货信息：重庆市,重庆市,渝中区,某某某街道江南大道96号某某小区,王大锤，手机：136****6954</div>
            </div>
        </div>
        <div class="select-pay">
             <div class="pay-money">
                 <div class="pay-money-font">支付金额：</div>
                 <div class="pay-money-num">￥1178</div>
             </div>
        <div class="type-select">
            <div class="type-item" v-for="(item,index) in typelist" :key="index"  @click="change(typelist,index)">
                 <div :class="[item.ischeck?'type-left2':' type-left']"></div>
                 <div class="type-right">
                     <img :src="item.img" alt="">
                 </div>
            </div>
        </div>
        <div class="instantly" @click="toshowmy()">立即支付</div>
        </div>
       
    </div>
</template>
<script>
import Topbar from '../../PCshop/components/topbar.vue'

export default {
    components:{
       Topbar
    },
    data(){
        return{
             typelist:[
                 {
                     ischeck:true,
                     img:require('../../../assets/images/pld2.png')
                 },
                  {
                     ischeck:false,
                     img:require('../../../assets/images/zfb.png')
                 },
                  {
                     ischeck:false,
                     img:require('../../../assets/images/wx.png')
                 },
             ]
        }
    },
    methods:{
        change(arr,index){
          // console.log(arr);
          arr=arr.map(item=>{
              item.ischeck=false;
              return item
          });
          this.$set(arr[index],'ischeck','true');
      },
      toshowmy(){
           this.$router.push({ name: 'PCshop/goods/goodssucc'});
      }
    }
}
</script>
<style lang="scss" scoped>
   .pay-top{
        width: 1100px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin: 20px auto;
        padding:30px 20px 22px 30px;
        .pay-top-font{
             border-bottom: 1px solid #EEEEEE;
             padding-bottom: 21px;
            .pay-top-1{
                    padding-left: 28px;
                    height: 28px;
                    line-height: 28px;
                    background-image: url('../../../assets/images/pay.png');
                    background-repeat: no-repeat;
                    background-position:0px 2px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #333333;
            }
            .pay-top-2{
                display: flex;
                font-size: 14px;
                color: #333333;
                margin-top: 20px;

                .pay-time{
                    color: #F40707;
                }
            }
        }
       .pay-bottom-font{
           margin-top: 20px;
        }
   }
    .select-pay{
        width: 1100px;
        height: 410px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin: 0 auto;
        padding:30px 20px 22px 30px;
        .pay-money{
            display: flex;
            border-bottom:1px solid #EEEEEE;
            padding-bottom: 20px;
            .pay-money-font{
                font-size: 14px;
                color: #333333;
            }
            .pay-money-num{
                font-size: 18px;
                font-weight: 800;
                color: #F40707;
                margin-top: -5px;
            }
        }
        .type-select{
          
            .type-item{
                display: flex;
                margin-top: 40px;
            .type-left{
                width: 18px;
                height: 18px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                border-radius: 50%;
            }
            .type-left2{
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-image: url('../../../assets/images/pay-select.png');
                background-repeat: no-repeat;
            }
            .type-right{
                margin-left: 52px;
                margin-top: -5px;
            }
          }
        }
      }
    .instantly{
        width: 211px;
        height: 44px;
        background: #FA6639;
        text-align: center;
        line-height: 44px;
        margin-top: 40px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
    }
</style>